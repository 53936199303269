var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-bar-side"},[_c('v-navigation-drawer',{staticClass:"side-drawer",attrs:{"mini-variant":true,"mini-variant-width":90,"permanent":true,"height":"100vh","color":"white","width":"264","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('router-link',{staticClass:"pa-5 pointer lightGrey h-90",attrs:{"to":{ name: 'projects', params: { wId: _vm.activeWorkspaceId } }}},[_c('v-img',{attrs:{"alt":"Sourcery Logo","width":"100%","src":require("@/assets/icons/mini-logo.svg")}})],1),(_vm.recentWorkspaceList.length)?_c('div',{staticClass:"flex-grow-1 d-flex flex-column align-stretch justify-center pointer",on:{"click":function($event){_vm.workspaceDrawer = !_vm.workspaceDrawer}}},[_c('v-list',{staticClass:"px-4",attrs:{"dark":""}},[_vm._l((_vm.recentWorkspaceList.slice(0, 5)),function(ref){
var id = ref.id;
var name = ref.name;
var createdAt = ref.createdAt;
return _c('v-list-item',{key:id,class:[
            id === _vm.activeWorkspaceId ? 'v-list-item--active' : '',
            'my-6 pa-0'
          ],attrs:{"to":_vm.lessRefreshing ? { name: 'projects', params: { wId: id } } : {},"href":_vm.lessRefreshing ? '' : ("/workspace/" + id + "/projects")},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.changeWorkspace(id, true); }).apply(null, arguments)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:[
                  'v-list-item__cover',
                  _vm.customBgColor(createdAt) ]},'div',attrs,false),on),[_c('v-list-item-title',[_c('WorkspaceBubble',{class:["fs-16", _vm.customBgColor(createdAt)],attrs:{"size":45}},[_c('span',[_vm._v(_vm._s(name.substring(0, 2)))])])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(name))])])],1)}),_c('v-list-item',{staticClass:"my-6",attrs:{"light":""},on:{"click":function($event){$event.stopPropagation();_vm.workspaceDrawer = !_vm.workspaceDrawer}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',{staticClass:"v-list-item__title--cover"},[_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_c('WorkspaceBubble',{staticClass:"fs-16 lightGrey",attrs:{"size":45}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-dots-horizontal ")])],1)],1)],1)]}}],null,false,2794546955)},[_c('span',[_vm._v("Click to expand all workspaces")])])],1)],2)],1):_vm._e()],1),_c('v-navigation-drawer',{staticClass:"workspace-drawer",attrs:{"overlay-opacity":0,"width":390,"fixed":"","temporary":""},model:{value:(_vm.workspaceDrawer),callback:function ($$v) {_vm.workspaceDrawer=$$v},expression:"workspaceDrawer"}},[_c('WorkspaceNav',{on:{"close-drawer":function($event){_vm.workspaceDrawer = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }