<template>
  <div class="nav-bar-side">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="true"
      :mini-variant-width="90"
      :permanent="true"
      class="side-drawer"
      height="100vh"
      color="white"
      width="264"
      app>
      <!-- sourcery logo -->
      <router-link
        class="pa-5 pointer lightGrey h-90"
        :to="{ name: 'projects', params: { wId: activeWorkspaceId } }">
        <v-img
          alt="Sourcery Logo"
          width="100%"
          src="@/assets/icons/mini-logo.svg" />
      </router-link>

      <!-- listing first 5 workspaces -->
      <div
        v-if="recentWorkspaceList.length"
        class="flex-grow-1 d-flex flex-column align-stretch justify-center pointer"
        @click="workspaceDrawer = !workspaceDrawer">
        <v-list
          class="px-4"
          dark>
          <v-list-item
            v-for="({ id, name, createdAt }) in recentWorkspaceList.slice(0, 5)"
            :key="id"
            :to="lessRefreshing ? { name: 'projects', params: { wId: id } } : {}"
            :href="lessRefreshing ? '' : `/workspace/${id}/projects`"
            :class="[
              id === activeWorkspaceId ? 'v-list-item--active' : '',
              'my-6 pa-0'
            ]"
            @click.stop="() => changeWorkspace(id, true)">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  :class="[
                    'v-list-item__cover',
                    customBgColor(createdAt),
                  ]"
                  v-on="on">
                  <v-list-item-title>
                    <WorkspaceBubble
                      :size="45"
                      :class="[`fs-16`, customBgColor(createdAt)]">
                      <span>{{ name.substring(0, 2) }}</span>
                    </WorkspaceBubble>
                  </v-list-item-title>
                </div>
              </template>
              <span>{{ name }}</span>
            </v-tooltip>
          </v-list-item>

          <!-- toggle more workspaces -->
          <v-list-item
            class="my-6"
            light
            @click.stop="workspaceDrawer = !workspaceDrawer">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="v-list-item__title--cover">
                  <v-list-item-title
                    v-bind="attrs"
                    v-on="on">
                    <WorkspaceBubble
                      :size="45"
                      class="fs-16 lightGrey">
                      <v-icon color="black">
                        mdi-dots-horizontal
                      </v-icon>
                    </WorkspaceBubble>
                  </v-list-item-title>
                </div>
              </template>
              <span>Click to expand all workspaces</span>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>

    <!-- drawer to list workspaces -->
    <v-navigation-drawer
      v-model="workspaceDrawer"
      :overlay-opacity="0"
      :width="390"
      class="workspace-drawer"
      fixed
      temporary>
      <WorkspaceNav
        @close-drawer="workspaceDrawer = false" />
    </v-navigation-drawer>
  </div>
</template>
<script>
import {
  mapMutations, mapState, mapGetters,
} from 'vuex';
// mixins
import AppNavBar from '@/mixins/AppNavBar';
import workspaceListing from '@/mixins/workspaceListing';
// components
import WorkspaceBubble from '@/components/Workspace/WorkspaceBubble';
import WorkspaceNav from '@/components/Workspace/WorkspaceNav';
export default {
  name: 'AppNavBarSide',
  components: {
    WorkspaceBubble,
    WorkspaceNav,
  },
  mixins: [AppNavBar, workspaceListing],
  data() {
    return {
      workspaceDrawer: false,
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', ['lessRefreshing']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState({
      navigationDrawer: state => state.navigationDrawer,
    }),
    drawer: {
      get() {
        return this.navigationDrawer;
      },
      set(val) {
        this.setNavigationDrawer(val);
      },
    },
  },
  methods: {
    ...mapMutations({
      setNavigationDrawer: 'setNavigationDrawer',
    }),
  },
};
</script>
<style lang="scss">
.nav-bar-side {
  .side-drawer {
    z-index: 10 !important;
  }

  .v-navigation-drawer {
    &__content {
      display: flex;
      flex-direction: column;
    }

    &__border {
      background-color: rgba(12, 12, 12, 0.25) !important;
    }

    .h-90 {
      height: 90px;
    }

    .v-list {
      &-item {
        &::before, &::after {
          display: none;
        }

        &__cover {
          background-color: transparent !important;
        }

        &--active {
          .v-list-item__cover {
            border-radius: 100%;
            border: 2px;
            border-style: solid;
            padding: 4px;
            background-color: white !important;
          }

          &:hover::before, &::before {
            opacity: 0;
          }
        }
      }
    }
  }

  .vertical-middle {
    vertical-align: middle;
  }

  .workspace-drawer {
    box-shadow: none;
    z-index: 9;
    left: 90px;
    padding-block-start: 90px;
  }
}
</style>
